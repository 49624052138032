import { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthProvider";
import { useParams } from "react-router-dom";
//Lang
import Translate from "../i18n/Translate";
//Hooks
import { useInView } from "react-intersection-observer";
//Comps

import tower_logo from "../assets/images/tower_logo.svg";
import NewPasswordForm from "../components/NewPasswordForm";

const DOMAIN = process.env.REACT_APP_DOMAIN;

/*
    Esta pagina seria accedida desde el link que recibe el usuario en su email. https://{{domain}}/login/newpassword/:token
    Ese link contendra un token que deberemos enviar para generar una nueva contraseña.
*/

const NewPassword = () => {
    const { token } = useParams();
    const { setValidateWith } = useContext(AuthContext);

    const [ref2, inView2] = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    //Esta ruta es solo para cambiar la contraseña, entonces a este punto el usuario ingreso aca desde el link del mail para cambiar constraseña. Asique necesitamos decirle a la pagina que el logueo, luego de que cambie la contraseña va a ser con tower no con witwan.
    useEffect(() => {
        setValidateWith("tower");
    }, []);

    return (
        <main className="prehome-login">
            <section className="prehome-login__section">
                <div className="prehome-login__container">
                    <div className="logo">
                        <a href={`${DOMAIN}`} rel="noreferrer">
                            <img src={tower_logo} alt="tower-travel logo" />
                        </a>
                    </div>
                    <h1 ref={ref2} className={`title ${inView2 ? "appear" : ""}`}>
                        <Translate langkey="login.changePassword" />
                    </h1>
                    <NewPasswordForm urlToken={token} />
                </div>
            </section>
        </main>
    );
};

export default NewPassword;
