import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { I18nContext } from "../context/I18nProvider";
import api from "../api";
import swal from "sweetalert";
//Lang
import { useTranslation } from "react-i18next";
import Translate from "../i18n/Translate";
//Form
import { useForm } from "react-hook-form";
//Comps
import SpinnerEllipsis from "./loaders/SpinnerEllipsis";
//SVGs
import WarningSvg from "./svg/WarningSvg";
import WatchUnwatch from "./svg/WatchUnwatch";

//TODO Terminar de conectar cuando este funcionando bien la API

const NewPasswordForm = ({ urlToken }) => {
    // console.log(urlToken);
    const { language } = useContext(I18nContext);
    const [watchPassword1, setWatchPassword1] = useState(false);
    const [watchPassword2, setWatchPassword2] = useState(false);

    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors },
    } = useForm({
        mode: "onChange",
    });

    const { t } = useTranslation();

    const onSubmit = async (data) => {
        setLoading(true);

        try {
            const response = await api.login.changePassword({
                token: urlToken,
                password: data.password,
                language: language,
            });

            if (response.status) {
                swal({
                    title: "Su contraseña ha sido modificada correctamente!",
                    text: "Por favor dirijase al login para iniciar sesión.",
                    icon: "success",
                    button: "Cerrar",
                    timer: 8000,
                });
                //Reseteamos
                reset();
            } else {
                swal({
                    title: "Error!",
                    text: `${response.message}`,
                    icon: "error",
                    button: "Cerrar",
                    timer: 8000,
                });
            }
        } catch (error) {
            console.log("CATCH  ERROR");
            console.error(error);
            swal({
                title: "Error!",
                text: "Ha ocurrido un error, por favor intente nuevamente.",
                icon: "error",
                button: "Cerrar",
                timer: 8000,
            });
            reset();
        } finally {
            setLoading(false);
        }
    };

    let passwordRegistration = register("password", {
        required: `${t("form.requiredField")}`,
        minLength: {
            value: 8,
            message: `${t("form.minChars", { num: 8 })}`,
        },
    });
    let passwordConfirmRegistration = register("passwordConfirm", {
        required: `${t("form.requiredField")}`,
        minLength: {
            value: 8,
            message: `${t("form.minChars", { num: 8 })}`,
        },
        validate: (value) => value === getValues("password") || t("form.passwordNotMatch"),
    });

    return (
        <form className="form-tower" onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="input-group">
                <label htmlFor="password">
                    <Translate langkey="form.labels.password" />
                </label>

                <input
                    type={`${watchPassword1 ? "text" : "password"}`}
                    id="password"
                    {...passwordRegistration}
                    autoComplete="on"
                    aria-invalid={errors.password ? "true" : "false"}
                />
                <span className="watch-pass" onClick={() => setWatchPassword1(!watchPassword1)}>
                    <WatchUnwatch watching={watchPassword1} />
                </span>
                {errors.password && (
                    <span className="error-msg" role="alert">
                        {errors.password.message}
                    </span>
                )}
                {errors.password && (
                    <span className="error-icon">
                        <WarningSvg />
                    </span>
                )}
            </div>
            <div className="input-group">
                <label htmlFor="password-confirm">
                    <Translate langkey="form.labels.passwordConfirm" />
                </label>

                <input
                    type={`${watchPassword2 ? "text" : "password"}`}
                    id="password-confirm"
                    {...passwordConfirmRegistration}
                    autoComplete="on"
                    aria-invalid={errors.passwordConfirm ? "true" : "false"}
                />
                <span className="watch-pass" onClick={() => setWatchPassword2(!watchPassword2)}>
                    <WatchUnwatch watching={watchPassword2} />
                </span>
                {errors.passwordConfirm && (
                    <span className="error-msg" role="alert">
                        {errors.passwordConfirm.message}
                    </span>
                )}
                {errors.passwordConfirm && (
                    <span className="error-icon">
                        <WarningSvg />
                    </span>
                )}
            </div>

            <button className="submit-btn btn" type="submit">
                {loading ? (
                    <SpinnerEllipsis color={"primario"} />
                ) : (
                    <span>
                        <Translate langkey="form.saveNewPassword" />
                    </span>
                )}
            </button>

            {/* Para volver al login comun */}
            <Link to="/" className="back-login-btn">
                <Translate langkey="form.goSignIn" />
            </Link>
        </form>
    );
};

export default NewPasswordForm;
