/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, NavLink } from "react-router-dom";

import Translate from "../../i18n/Translate";
import { useTranslation } from "react-i18next";
//Comps
import Socials from "../../components/Socials";

//svg
import Logo from "../../assets/images/tower_logo.svg";
import Phone from "../../assets/images/icons/phone.svg";
import Mail from "../../assets/images/icons/envelope.svg";
import MailWhite from "../../assets/images/icons/envelope-white.svg";
import Location from "../../assets/images/icons/location.svg";

//SCSS VARIABLES
import colors from "../../assets/sass/variables/colors.module.scss";

const DOMAIN = process.env.REACT_APP_DOMAIN;

const Footer = ({ language }) => {
  const { t } = useTranslation();
  const { colorClaro, colorSecundario } = colors;

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__container__column1">
          <img
            src={Logo}
            alt="Tower Partners Logo"
            title="Tower Partners Logo"
          />

          <p>
            <Translate langkey="footer.column1.copy" />
          </p>

          <div className="footer__container__column1__info">
            <a href="#" target="_blank" rel="noreferrer">
              <img src={Location} alt="Location" />
              <span>
                <Translate langkey="footer.column1.address" />
              </span>
            </a>

            <a href="tel:+541152177400" target="_blank" rel="noreferrer">
              <img src={Phone} alt="Phone" />
              <Translate langkey="footer.column1.phone" />
            </a>

            <a
              href="mailto:info@towertravel.com.ar"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Mail} alt="Mail" />
              <Translate langkey="footer.column1.email" />
            </a>
          </div>

          <div className="footer__container__column1__social">
            <Socials fill={colorClaro} background={colorSecundario} />
          </div>
        </div>

        <div className="footer__container__column2">
          <h3>
            <Translate langkey="footer.column2.title" />
          </h3>

          <ul>
            <li>
              <a href={`${t("links.incoming.link")}`}>
                <Translate langkey="links.incoming.text" />
              </a>
            </li>
            <li>
              <a href={`${t("links.corporate.link")}`}>
                <Translate langkey="links.corporate.text" />
              </a>
            </li>
            <li>
              <a href={`${t("links.sports.link")}`}>
                <Translate langkey="links.sports.text" />
              </a>
            </li>
            <li>
              <a href={`${t("links.partners.link")}`}>
                <Translate langkey="links.partners.text" />
              </a>
            </li>
            <li>
              <a href={`${t("links.shine.link")}`}>
                <Translate langkey="links.shine.text" />
              </a>
            </li>
            <li>
              <a href={`${t("links.experience.link")}`}>
                <Translate langkey="links.experience.text" />
              </a>
            </li>
            <li>
              <a href={`${t("links.community.link")}`}>
                <Translate langkey="links.community.text" />
              </a>
            </li>
          </ul>
        </div>

        <div className="footer__container__column3">
          <h3>
            <Translate langkey="footer.column3.title" />
          </h3>

          <ul>
            <li>
              <Link to={`${t("routes.contact")}`}>
                <Translate langkey="footer.column3.links.link1" />
              </Link>
            </li>

            {/* <li>
              <Link to="/">
                <Translate langkey="footer.column3.links.link2" />
              </Link>
            </li> */}
          </ul>
        </div>

        <div className="footer__container__column4">
          <h3>
            <Translate langkey="footer.column4.title" />
          </h3>

          <p>
            <Translate langkey="footer.column4.copy" />
          </p>
          <p className="footer__container__column4-input">
            <input type="text" placeholder="Email" />
            <button>
              <img src={MailWhite} alt="Mail Icon" />
            </button>
          </p>
        </div>

        <div className="footer__container__column5">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://servicios1.afip.gov.ar/clavefiscal/qr/mobilePublicInfo.aspx?req=e1ttZXRob2Q9Z2V0UHVibGljSW5mb11bcGVyc29uYT0zMDcwODQ3NDI4OV1bdGlwb2RvbWljaWxpbz0wXVtzZWN1ZW5jaWE9MF1bdXJsPWh0dHA6Ly93d3cudG93ZXJ0cmF2ZWwuY29tLmFyL119"
          >
            <img
              src="https://www.towertravel.com.ar/web/assets/img/DataFIscal-QR.png"
              alt="logo"
            />
          </a>

          <div>
            <p>
              <Translate langkey="footer.column5.title" />
            </p>
            <p>
              <Translate langkey="footer.column5.copy" />
            </p>
            <br />
            <p>
              <Translate langkey="footer.column5.copy2" />
            </p>
          </div>
        </div>

        <div className="footer__container__footer">
          <div className="copys">
            <p>
              <Translate langkey="footer.footer.copy1" />
            </p>
            <p>
              <Translate langkey="footer.footer.copy2" />{" "}
              <a
                href="https://bigsurbranding.com/"
                target="_blank"
                rel="noreferrer"
              >
                <span>Big Sur Branding</span>
              </a>
            </p>
          </div>

          <nav>
            <NavLink
              to={`${t("routes.home")}`}
              exact
              className="footer-nav-link"
            >
              <Translate langkey="routes.home-text" />
            </NavLink>
            <NavLink to={`${t("routes.about")}`} className="footer-nav-link">
              <Translate langkey="routes.about-text" />
            </NavLink>
            <NavLink to={`${t("routes.contact")}`} className="footer-nav-link">
              <Translate langkey="routes.contact-text" />
            </NavLink>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
