import { useContext } from "react";
import swal from "sweetalert";
import api from "../api";
//Lang
import { useTranslation } from "react-i18next";
import Translate from "../i18n/Translate";
import { I18nContext } from "../context/I18nProvider";

//Form
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

//SVGs
import WarningSvg from "./svg/WarningSvg";

//TODO : Aca resta coordinar las mismas validaciones que hacen en el back con Joi.
//Quedaron en pasarnoslas, sino se puede trabajar con Joi por lo menos se deberian hacer validaciones que no choquen con las validaciones del backend.

const SignUpForm = () => {
    const { language } = useContext(I18nContext);

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        reValidateMode: "onChange",
    });

    const { t } = useTranslation();

    const country = [
        { value: "argentina", label: "Argentina" },
        { value: "chile", label: "Chile" },
        { value: "colombia", label: "Colombia" },
    ];

    const cities = [
        { value: "buenos-aires", label: "Buenos Aires" },
        { value: "santiago", label: "Santiago" },
        { value: "lima", label: "Lima" },
    ];

    const ivaOptions = [
        { value: "iva-responsable-inscripto", label: "IVA Responsable Inscripto" },
        {
            value: "iva-responsable-no-inscripto",
            label: "IVA Responsable no Inscripto",
        },
        { value: "iva-no-responsable", label: "IVA no Responsable" },
        { value: "iva-sujeto-exento", label: "IVA Sujeto Exento" },
        { value: "consumidor-final", label: "Consumidor Final" },
        { value: "responsable-monotributo", label: "Responsable Monotributo" },
        { value: "sujeto-no-categorizado", label: "Sujeto no Categorizado" },
        { value: "proveedor-del-exterior", label: "Proveedor del Exterior" },
        { value: "cliente-del-exterior", label: "Cliente del Exterior" },
        {
            value: "iva-liberado-ley-nº-19-640",
            label: "IVA Liberado – Ley Nº 19.640",
        },
        {
            value: "iva-responsable-inscripto-agente-de-percepción",
            label: "IVA Responsable Inscripto – Agente de Percepción",
        },
        {
            value: "pequeño-contribuyente",
            label: "Eventual Pequeño Contribuyente Eventual",
        },
        { value: "monotributista-social", label: "Monotributista Social" },
        {
            value: "pequeño-contribuyente-eventual-social",
            label: "Pequeño Contribuyente Eventual Social",
        },
    ];

    let firstName = register("firstName", {
        required: `${t("form.requiredField")}`,
        pattern: {
            value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g,
            message: `${t("form.wrongField")}`,
        },
    });

    let firstLastName = register("firstLastName", {
        required: `${t("form.requiredField")}`,
        pattern: {
            value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g,
            message: `${t("form.wrongField")}`,
        },
    });

    let address = register("address", {
        required: `${t("form.requiredField")}`,
    });

    let commercialName = register("operator", {
        required: `${t("form.requiredField")}`,
        pattern: {
            value: /(^[A-Za-z ]+$)/g,
            message: `${t("form.wrongField")}`,
        },
    });

    let socialReason = register("socialReason", {
        required: `${t("form.requiredField")}`,
        pattern: {
            value: /(^[A-Za-z ]+$)/g,
            message: `${t("form.wrongField")}`,
        },
    });

    let fiscalAddres = register("fiscalAddres", {
        required: `${t("form.requiredField")}`,
    });

    let tributaryRegistration = register("tributaryRegistration", {
        required: `${t("form.requiredField")}`,
        pattern: {
            value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/g,
            message: `${t("form.wrongField")}`,
        },
    });

    let email = register("email", {
        required: `${t("form.requiredField")}`,
        pattern: {
            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: `${t("form.wrongField")}`,
        },
    });

    let phone = register("phone", {
        required: `${t("form.requiredField")}`,
        pattern: {
            value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/g,
            message: `${t("form.wrongField")}`,
        },
    });

    const onSubmit = async (data) => {
        const apiBody = {
            personalData: {
                firstName: data.firstName,
                firstLastName: data.firstLastName,
                country: data.countrie,
                city: data.city,
                address: data.address,
            },
            companyData: {
                commercialName: data.operator,
                socialReason: data.socialReason,
                city: data.businessCity,
                fiscalAddres: data.fiscalAddres,
                ivaCondition: data.ivaCondition,
                tributaryRegistration: data.tributaryRegistration,
                email: data.email,
                phone: data.phone,
            },
        };

        try {
            const response = await api.register.create({
                body: apiBody,
                language: language,
            });

            if (response.status) {
                swal({
                    title: "Su solicitud se ha enviado correctamente!",
                    text: "Muy pronto nos comunicaremos con usted!",
                    icon: "success",
                    button: "Cerrar",
                    timer: 8000,
                });
                //Reseteamos
                reset();
            }
        } catch (error) {
            console.log("CATCH  ERROR");
            console.error(error);
        }
    };

    return (
        <form className="signUp-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="columns">
                <div>
                    <h2>Ingresa tus datos personales</h2>

                    {/* Nombre */}
                    <div className="input-group">
                        <input
                            type="text"
                            id="firstName"
                            // placeholder={t("form.labels.operator")}
                            placeholder="Nombre*"
                            {...firstName}
                            aria-invalid={errors.firstName ? "true" : "false"}
                        />
                        {errors.firstName && (
                            <span className="error-msg" role="alert">
                                {errors.firstName.message}
                            </span>
                        )}
                        {errors.firstName && (
                            <span className="error-icon">
                                <WarningSvg />
                            </span>
                        )}
                    </div>

                    {/* Apellido */}
                    <div className="input-group">
                        <input
                            type="text"
                            id="firstLastName"
                            // placeholder={t("form.labels.operator")}
                            placeholder="Apellido*"
                            {...firstLastName}
                            aria-invalid={errors.firstLastName ? "true" : "false"}
                        />
                        {errors.firstLastName && (
                            <span className="error-msg" role="alert">
                                {errors.firstLastName.message}
                            </span>
                        )}
                        {errors.firstLastName && (
                            <span className="error-icon">
                                <WarningSvg />
                            </span>
                        )}
                    </div>

                    {/* País */}
                    <div className="input-group">
                        <Controller
                            render={({ field }) => (
                                <Select
                                    classNamePrefix="react_select"
                                    placeholder="País*"
                                    value={country.find((c) => c.value === field.value) || null}
                                    options={country}
                                    onChange={(val) => field.onChange(val.value)}
                                />
                            )}
                            name="countrie"
                            control={control}
                            rules={{ required: `${t("form.requiredField")}` }}
                        />
                        {errors.countrie && (
                            <span className="error-msg" role="alert">
                                {errors.countrie.message}
                            </span>
                        )}
                    </div>

                    {/* Ciudad */}
                    <div className="input-group">
                        <Controller
                            render={({ field }) => (
                                <Select
                                    classNamePrefix="react_select"
                                    placeholder="Ciudad*"
                                    value={cities.find((c) => c.value === field.value) || null}
                                    options={cities}
                                    onChange={(val) => field.onChange(val.value)}
                                />
                            )}
                            name="city"
                            control={control}
                            rules={{ required: `${t("form.requiredField")}` }}
                        />

                        {errors.city && (
                            <span className="error-msg" role="alert">
                                {errors.city.message}
                            </span>
                        )}
                    </div>

                    {/* Dirección */}
                    <div className="input-group">
                        <input
                            type="text"
                            id="address"
                            // placeholder={t("form.labels.operator")}
                            placeholder="Dirección*"
                            {...address}
                            aria-invalid={errors.address ? "true" : "false"}
                        />
                        {errors.address && (
                            <span className="error-msg" role="alert">
                                {errors.address.message}
                            </span>
                        )}
                        {errors.address && (
                            <span className="error-icon">
                                <WarningSvg />
                            </span>
                        )}
                    </div>
                </div>

                <div>
                    <h2>Ingresa los datos de tu empresa</h2>

                    {/* Nombre de la agencia / Operador */}
                    <div className="input-group">
                        <input
                            type="text"
                            id="operator"
                            placeholder={t("form.labels.operator")}
                            {...commercialName}
                            aria-invalid={errors.operator ? "true" : "false"}
                        />
                        {errors.operator && (
                            <span className="error-msg" role="alert">
                                {errors.operator.message}
                            </span>
                        )}
                        {errors.operator && (
                            <span className="error-icon">
                                <WarningSvg />
                            </span>
                        )}
                    </div>

                    {/* Razón social */}
                    <div className="input-group r-social">
                        <input
                            type="text"
                            id="socialReason"
                            placeholder={t("form.labels.socialReason")}
                            {...socialReason}
                            aria-invalid={errors.socialReason ? "true" : "false"}
                        />
                        {errors.socialReason && (
                            <span className="error-msg" role="alert">
                                {errors.socialReason.message}
                            </span>
                        )}
                        {errors.socialReason && (
                            <span className="error-icon bottom-fixed">
                                <WarningSvg />
                            </span>
                        )}
                    </div>

                    {/* Ciudad de negocio */}
                    <div className="input-group">
                        <Controller
                            render={({ field }) => (
                                <Select
                                    classNamePrefix="react_select"
                                    placeholder="Ciudad*"
                                    value={cities.find((c) => c.value === field.value) || null}
                                    options={cities}
                                    onChange={(val) => field.onChange(val.value)}
                                />
                            )}
                            name="businessCity"
                            control={control}
                            rules={{ required: `${t("form.requiredField")}` }}
                        />

                        {errors.businessCity && (
                            <span className="error-msg" role="alert">
                                {errors.businessCity.message}
                            </span>
                        )}
                    </div>

                    {/* Dirección fiscal */}
                    <div className="input-group">
                        <input
                            type="text"
                            id="fiscalAddres"
                            // placeholder={t("form.labels.socialReason")}
                            placeholder="Dirección fiscal*"
                            {...fiscalAddres}
                            aria-invalid={errors.fiscalAddres ? "true" : "false"}
                        />
                        {errors.fiscalAddres && (
                            <span className="error-msg" role="alert">
                                {errors.fiscalAddres.message}
                            </span>
                        )}
                        {errors.fiscalAddres && (
                            <span className="error-icon">
                                <WarningSvg />
                            </span>
                        )}
                    </div>

                    {/* Condición ante el IVA */}
                    <div className="input-group">
                        <Controller
                            render={({ field }) => (
                                <Select
                                    classNamePrefix="react_select"
                                    placeholder="Condición ante el IVA*"
                                    options={ivaOptions}
                                    value={ivaOptions.find((c) => c.value === field.value) || null}
                                    onChange={(val) => field.onChange(val.value)}
                                />
                            )}
                            name="ivaCondition"
                            control={control}
                            rules={{ required: `${t("form.requiredField")}` }}
                        />

                        {errors.ivaCondition && (
                            <span className="error-msg" role="alert">
                                {errors.ivaCondition.message}
                            </span>
                        )}
                    </div>

                    {/* Registro fiscal/tributario nacional (CUIT)* */}
                    <div className="input-group">
                        <input
                            type="text"
                            id="tributaryRegistration"
                            // placeholder={t("form.labels.address")}
                            placeholder="Registro fiscal/tributario nacional (CUIT)*"
                            {...tributaryRegistration}
                            aria-invalid={errors.address ? "true" : "false"}
                        />
                        {errors.tributaryRegistration && (
                            <span className="error-msg" role="alert">
                                {errors.tributaryRegistration.message}
                            </span>
                        )}
                        {errors.tributaryRegistration && (
                            <span className="error-icon">
                                <WarningSvg />
                            </span>
                        )}
                    </div>

                    {/* Email */}
                    <div className="input-group">
                        <input
                            type="email"
                            id="email"
                            placeholder={t("form.labels.email")}
                            {...email}
                            aria-invalid={errors.email ? "true" : "false"}
                        />
                        {errors.email && (
                            <span className="error-msg" role="alert">
                                {errors.email.message}
                            </span>
                        )}
                        {errors.email && (
                            <span className="error-icon">
                                <WarningSvg />
                            </span>
                        )}
                    </div>

                    {/* Teléfono */}
                    <div className="input-group">
                        <input
                            type="phone"
                            id="phone"
                            placeholder={t("form.labels.phone")}
                            {...phone}
                            aria-invalid={errors.phone ? "true" : "false"}
                        />
                        {errors.phone && (
                            <span className="error-msg" role="alert">
                                {errors.phone.message}
                            </span>
                        )}
                        {errors.phone && (
                            <span className="error-icon">
                                <WarningSvg />
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <button className="submit-btn btn" type="submit">
                <Translate langkey="form.signUp" />
            </button>
        </form>
    );
};

export default SignUpForm;
