import { useState, useEffect, createContext } from "react";
import { useLocation } from "react-router-dom";
import api from "../api";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const location = useLocation();
    const useQuery = () => new URLSearchParams(location.search);
    const redirectUrlParam = useQuery().get("redirectURL");

    const defaultRedirectUrl = process.env.REACT_APP_DEFAULT_REDIRECT_URL;

    //Datos con los que el usuario se loguea ( SignInForm )
    const [userCredentials, setUserCredentials] = useState({
        email: "",
        password: "",
    });
    //Informacion del estado del logueo ( SignInForm )
    const [isAuthenticated, setIsAuthenticated] = useState({
        loading: false,
        error: "",
        status: {
            value: "",
            message: "",
        },
        token: "",
    });

    // Buscamos en la url un parametro llamado "redirectURL"
    const [validateWith, setValidateWith] = useState(
        redirectUrlParam === "partners" || redirectUrlParam === "reservas-online" || redirectUrlParam === null
            ? "witwan"
            : "tower"
    );

    validateWith === "witwan" ? console.log("Vamos a loguear con witwan") : console.log("Vamos a loguear con tower");

    /*
    Lo primero que debemos hacer es ver si el usuario ya esta logueado, para evitar el doble logueo.
    Si el logueo es con WitWan y existe un token "tower_witwan_info" vemos si el iat del token no supero las 8 hrs de diferencia con la hora actual.(new Date().getTime() - token.iat), si es valido redireccionamos a partners.
    Si el logueo es con TowerTravel y existe un token "tower_user_token", utilizamos el endpoint de "Obtener datos de Usuario por Token" para ver si el token es valido, si es valid redireccionamos a prehome o abm.
    */
    const validateUser = async (token, language) => {
        setIsAuthenticated({ ...isAuthenticated, loading: true });
        try {
            console.log("VALIDANDO TOKEN DE TOWER");
            const response = await api.validateUser.get({ token: token, language: language });

            if (response.status) {
                console.log("USUARIO VALIDADO");
                setIsAuthenticated({
                    loading: false,
                    error: "",
                    status: {
                        value: "SUCCESS",
                        message: "",
                    },
                    token: token,
                });
            } else {
                console.log("token tower invalido, removiendo token");
                setIsAuthenticated({ ...isAuthenticated, loading: false });
                localStorage.removeItem("tower_user_token");
            }
        } catch (error) {
            console.error(error);
        }
    };

    //Solo para validar en caso de haber token de tower en el storage
    useEffect(() => {
        if (validateWith === "witwan") {
            const witwanToken = JSON.parse(localStorage.getItem("tower_witwan_info"));

            if (witwanToken) {
                console.log("Existe un token de witwan, analizando caducación..");
                setIsAuthenticated({ ...isAuthenticated, loading: true });
                const iat = witwanToken.iat;
                const now = new Date().getTime();
                const diff = now - iat;

                //Si la diferencia es menor a 8hs mantenemos el token
                if (diff < 8 * 60 * 60 * 1000) {
                    setIsAuthenticated({
                        loading: false,
                        error: "",
                        status: {
                            value: "SUCCESS",
                            message: "",
                        },
                        token: witwanToken,
                    });
                } else {
                    console.log("Removiendo token witwan, token expirado.");
                    setIsAuthenticated({ ...isAuthenticated, loading: false });
                    //De lo contrario eliminamos el token del storage
                    localStorage.removeItem("tower_witwan_info");
                }
            }
        } else {
            console.log("Validando si hay token de tower..");
            const towerToken = JSON.parse(localStorage.getItem("tower_user_token"));

            if (towerToken) {
                console.log("Si hay token de tower");
                validateUser(towerToken, "es");
            }
        }
    }, []);

    // Hacemos el llamado a la api para registrar al usuario
    const signIn = async (credentials) => {
        setIsAuthenticated({ ...isAuthenticated, loading: true });

        if (validateWith === "witwan") {
            //LOGUEO CON API DE WITWAN

            //Para encriptar base 64 email y passsword
            const credentialsEncoded = window.btoa(`${credentials.email}|||${credentials.password}`);

            try {
                const data = await api.login.signInWitWan(credentialsEncoded);

                if (data.result.status === "OK") {
                    //El logueo fue exitoso
                    setIsAuthenticated({
                        ...isAuthenticated,
                        loading: false,
                        status: {
                            value: "SUCCESS",
                            message: "",
                        },
                        token: {
                            email: data.result.rss.email,
                            id: data.result.rss.id,
                            name: data.result.rss.nombre,
                            iat: new Date().getTime(),
                            credentials: credentialsEncoded,
                        },
                    });
                } else {
                    //El logueo fue fallido
                    setIsAuthenticated({
                        ...isAuthenticated,
                        loading: false,
                        status: {
                            value: "FAILED",
                            message: data.result.error,
                        },
                    });
                }
            } catch (error) {
                console.error(error);

                setIsAuthenticated({
                    ...isAuthenticated,
                    loading: false,
                    error: error,
                });
            }
        } else {
            //LOGUEO CON API DE TOWER
            try {
                const data = await api.login.signInTowerTravel(credentials);

                if (data.status === true) {
                    //El logueo fue exitoso
                    setIsAuthenticated({
                        ...isAuthenticated,
                        loading: false,
                        status: {
                            value: "SUCCESS",
                            message: data.message,
                        },
                        token: data.result.token,
                    });
                } else {
                    //El logueo fue fallido
                    setIsAuthenticated({
                        ...isAuthenticated,
                        loading: false,
                        status: {
                            value: "FAILED",
                            message: data.message,
                        },
                    });
                }
            } catch (error) {
                console.error(error);

                setIsAuthenticated({
                    ...isAuthenticated,
                    loading: false,
                    error: error,
                });
            }
        }
    };

    // Enviamos las credenciales a la api para registrar al usuario
    useEffect(() => {
        const { email, password } = userCredentials;

        if (email !== "" && password !== "") {
            signIn({ email, password });
        }
    }, [userCredentials]);

    //Si esta autenticado seteamos en el localStorage y redireccionamos
    useEffect(() => {
        if (isAuthenticated.status.value === "SUCCESS") {
            if (validateWith === "witwan") {
                // console.log(isAuthenticated.token);
                //Guardo en el localStorage la info recibida por WITWAN.
                localStorage.setItem("tower_witwan_info", JSON.stringify(isAuthenticated.token));
                //Si no viene de ningun sitio, por default redirecciono a Partners
                if (redirectUrlParam === null) {
                    window.location.href = `${window.location.origin}/${defaultRedirectUrl}`;
                    return;
                }
                //Si viene de un sitio, redirecciono a ese sitio
                if (redirectUrlParam) {
                    window.location.href = `${window.location.origin}/${redirectUrlParam}`;
                    return;
                }
            } else {
                //Guardo en el localStorage la info recibida del usuario para leerla en ABM.
                //ABM se asegurara de pedir los datos del usuario a traves de este token.
                localStorage.setItem("tower_user_token", JSON.stringify(isAuthenticated.token));

                //Si viene de un sitio (deberia serlo ya que este caso no debe ser null ni reservas ni partners), redirecciono a ese sitio
                if (redirectUrlParam) {
                    window.location.href = `${window.location.origin}/${redirectUrlParam}`;
                    return;
                } else {
                    window.location.href = `${window.location.origin}/prehome`;
                    return;
                }
            }
        }
    }, [isAuthenticated, defaultRedirectUrl, redirectUrlParam]);

    return (
        <AuthContext.Provider
            value={{ userCredentials, setUserCredentials, isAuthenticated, validateWith, setValidateWith }}
        >
            {children}
        </AuthContext.Provider>
    );
};
