import colors from "../../assets/sass/variables/colors.module.scss";

const SpinnerEllipsis = ({ color }) => {
    const { colorPrimario, colorSecundario } = colors;

    const backgroundColor = () => {
        switch (color) {
            case "primario":
                return { backgroundColor: colorPrimario };
            case "secundario":
                return { backgroundColor: colorSecundario };
            default:
                return { backgroundColor: colorPrimario };
        }
    };

    return (
        <div className="lds-ellipsis">
            <div style={backgroundColor()}></div>
            <div style={backgroundColor()}></div>
            <div style={backgroundColor()}></div>
            <div style={backgroundColor()}></div>
        </div>
    );
};

export default SpinnerEllipsis;
