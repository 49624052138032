//Lang
import Translate from "../i18n/Translate";

//Hooks
import { useInView } from "react-intersection-observer";

//Comps
import SignUpForm from "../components/SignUpForm";
import logo_signup from "../assets/images/logo_signup.svg";

const DOMAIN = process.env.REACT_APP_DOMAIN;

const Signup = () => {
    const [ref2, inView2] = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    return (
        <main className="prehome-login">
            <section className="prehome__signUp">
                <div className="logo">
                    <a href={`${DOMAIN}`} rel="noreferrer">
                        <img src={logo_signup} alt="tower-travel logo" />
                    </a>
                </div>
                <h1 ref={ref2} className={`title ${inView2 ? "appear" : ""}`}>
                    <Translate langkey="login.signUpTitle" />
                </h1>

                <SignUpForm />
            </section>
        </main>
    );
};

export default Signup;
