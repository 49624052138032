
import ReactDOM from 'react-dom';
import App from './routes/App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

//i18n init
import './i18n/i18n'
//SASS
import './assets/sass/index.scss'

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

ReactDOM.render( <App />, document.getElementById('root') );


