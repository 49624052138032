const TOWER_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const TOWER_API_AUTHORIZATION = process.env.REACT_APP_API_AUTHORIZATION;
const TOWER_API_REGISTER = process.env.REACT_APP_API_REGISTER;
const WITWAN_API_REGISTER = process.env.REACT_APP_WITWAN_AUTH;

async function callApi(origin, endpoint, options = {}) {
    options.headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...options.headers,
    };

    const url = origin + endpoint;
    const response = await fetch(url, options);
    const data = await response.json();

    return data;
}

const api = {
    login: {
        signInTowerTravel({ email, password }) {
            return callApi(`${TOWER_API_BASE_URL}/${TOWER_API_AUTHORIZATION}`, "/login", {
                method: "POST",
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            });
        },
        signInWitWan(credentialsEncoded) {
            return callApi(`${WITWAN_API_REGISTER}${credentialsEncoded}`, "", {
                method: "GET",
            });
        },
        recoverPassword({ email, language }) {
            return callApi(`${TOWER_API_BASE_URL}/${TOWER_API_AUTHORIZATION}`, "/recoverpassword", {
                method: "POST",
                headers: {
                    "Accept-Language": language,
                },
                body: JSON.stringify({
                    email: email,
                }),
            });
        },
        changePassword({ token, password, language }) {
            return callApi(`${TOWER_API_BASE_URL}/${TOWER_API_AUTHORIZATION}`, "/recoverpassword", {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Accept-Language": language,
                },
                body: JSON.stringify({
                    password: password,
                }),
            });
        },
    },
    register: {
        create({ body, language }) {
            return callApi(`${TOWER_API_BASE_URL}/${TOWER_API_REGISTER}`, "", {
                method: "POST",
                headers: {
                    "Accept-Language": language,
                },
                body: JSON.stringify(body),
            });
        },
    },
    validateUser: {
        get({ token, language }) {
            // console.log(token);
            // console.log(language);

            return callApi(`${TOWER_API_BASE_URL}/${TOWER_API_AUTHORIZATION}`, "/verifysession", {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Accept-Language": language,
                },
            });
        },
    },
};

export default api;
