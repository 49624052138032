import React, { useContext } from "react";

import { AuthContext } from "../context/AuthProvider";

//Comps
import Loader from "../components/loaders/Loader";
import Footer from "../layout/footer/Footer";

const Layout = ({ children }) => {
    const { isAuthenticated, userCredentials } = useContext(AuthContext);

    //Solo para la primer autenticacion si se entro al login y hay un token
    //Si se esta cargando y si no hay ingresadas credenciales
    if (isAuthenticated.loading === true && (userCredentials.email === "" || userCredentials.password === "")) {
        return <Loader message="Autenticando..." />;
    }

    return (
        <div className="login-tower-travel">
            {children}
            <Footer />
        </div>
    );
};

export default Layout;
